import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SsTransactionDetails from 'views/pay-in-pay-out/SsTransaction/SsTransactionDetails';
import PG from 'views/Developer/PG';
import Proxy from 'views/Developer/Proxy';
import PayoutDetails from 'views/PG/PayoutDetails';
import PayinDetails from 'views/PG/PayinDetails';
import IPWhitelist from 'views/Developer/IPWhitelist';
import PayoutDashboard from 'views/dashboard/Payout';
import Payout1Dashboard from 'views/dashboard/Payout1';
import Secret from 'views/Developer/Secret';
import AffiliateSettlement from 'views/support/Request/AffiliateSettlement';
import AffilaiteInvoice from 'views/support/Request/AffiliateSettlement/Invoice';
import MerchantConfig from 'views/Developer/MerchantConfig';
import Affiliates from 'views/Developer/Affiliates';
import URL from 'views/Developer/Urls';
import Transactions from 'views/pay-in-pay-out/DebitBank/tranasctions';
import PayinClient from 'views/Developer/PayinClient';
import MerchantMeta from 'views/Developer/MerchantMeta';
import UploadReports from 'views/Developer/Upload';
import UPIApps from 'views/Developer/UPIApps';
import BankTxnReport from 'views/support/BankTxnReport';
import CreatePayout from 'views/Developer/CreatePayout';
import Bank from 'views/Developer/Bank';
import CustomerData from 'views/support/CustomerData';
import BulkWebhook from 'views/Developer/BulkWebhook';
import PayInLevels from 'views/dashboard/PayinLevels';
import LoggedInMerchants from 'views/Developer/LoggedInMerchants';
import SuperAdmins from 'views/Developer/SuperAdmins';
import MerchantIntentConfig from 'views/Developer/MerchantIntentConfig';
import ClickPayConfig from 'views/Developer/ClockPayConfig';
import Dashboard2 from 'views/dashboard/Dashboard2';
import PayoutControl from 'views/support/PayoutControl';
import PayInMeta from 'views/dashboard/Merchant/PayInMeta';
import BulkPayment from 'views/Developer/BulkPayment';
import UpdatePassword from 'views/Developer/UpdatePassword';
import IsGetBankTransaction from 'views/pay-in-pay-out/IsGetBankTransaction';
import UnauthLocations from 'views/Developer/UnauthLocations';
import MerchantUser from 'views/dashboard/MerchantUser';
import MerchantVPN from 'views/Developer/MerchantVPN';
import CBSettlement from 'views/Developer/CBSettlement';
// import Bouncer from 'views/Developer/Bouncer';
import Bots from 'views/Developer/BotCreds';
import Wallets from 'views/Developer/CryptoWallet';
import USDTTxn from 'views/pay-in-pay-out/USDTTxn';
import KeyConfig from 'views/Developer/KeyConfig';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));
const DashboardMerchant = Loadable(lazy(() => import('views/dashboard/Merchant')));
const DashboardUser = Loadable(lazy(() => import('views/dashboard/User')));

// pay in pay out pages
const Transaction = Loadable(lazy(() => import('views/pay-in-pay-out/Transaction')));
const SSTransaction = Loadable(lazy(() => import('views/pay-in-pay-out/SsTransaction')));
const Payout = Loadable(lazy(() => import('views/pay-in-pay-out/Payout')));
const PayoutWClient = Loadable(lazy(() => import('views/pay-in-pay-out/PayoutWClient')));
const ManualBank = Loadable(lazy(() => import('views/pay-in-pay-out/Bank')));
const DebitBank = Loadable(lazy(() => import('views/pay-in-pay-out/DebitBank')));
const PayoutBank = Loadable(lazy(() => import('views/pay-in-pay-out/PayoutBank')));
const BankTransaction = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction')));
const BankTransactionDetails = Loadable(lazy(() => import('views/pay-in-pay-out/BankTransaction/Details')));
const BankLogin = Loadable(lazy(() => import('views/pay-in-pay-out/BankLogin')));
const Reconcilation = Loadable(lazy(() => import('views/pay-in-pay-out/Reconcilation')));
const PayIn = Loadable(lazy(() => import('views/dashboard/Merchant/PayIn')));
const PayOut = Loadable(lazy(() => import('views/dashboard/Merchant/Payout')));

// support pages
const Settlement = Loadable(lazy(() => import('views/support/Settlement')));
const PayoutCredit = Loadable(lazy(() => import('views/support/PayoutCredit')));
const Reports = Loadable(lazy(() => import('views/support/Reports')));
const Logs = Loadable(lazy(() => import('views/support/Logs')));
const SettlementRequest = Loadable(lazy(() => import('views/support/Request/Settlement')));
const Webhooks = Loadable(lazy(() => import('views/support/Webhooks')));
const Invoice = Loadable(lazy(() => import('views/support/Request/Settlement/Invoice')));
const PayoutRequest = Loadable(lazy(() => import('views/support/Request/Payout')));

// developer
const Developer = Loadable(lazy(() => import('views/Developer')));

const childrenRoutes = [
    {
        path: '/developer',
        element: <Developer />
    },
    {
        path: '/default',
        element: <DashboardDefault />
    },
    {
        path: '/payout-dashboard',
        element: <PayoutDashboard />
    },
    // {
    //     path: '/dashboard2',
    //     element: <Dashboard2 />
    // },
    {
        path: '/payout1-dashboard',
        element: <Payout1Dashboard />
    },
    {
        path: '/payin-levels',
        element: <PayInLevels />
    },
    {
        path: '/dashboard/analytics',
        element: <DashboardAnalytics />
    },
    {
        path: '/dashboard/merchant',
        element: <DashboardMerchant />
    },
    {
        path: '/merchant/payin/:merchantId',
        element: <PayIn />
    },
    {
        path: '/merchant/payin-meta/:merchantId',
        element: <PayInMeta />
    },
    {
        path: '/payment/debit-banks/:debitBankId',
        element: <Transactions />
    },
    {
        path: '/merchant/payout/:merchantId',
        element: <PayOut />
    },
    {
        path: '/dashboard/user',
        element: <DashboardUser />
    },
    {
        path: '/dashboard/merchant-user',
        element: <MerchantUser />
    },
    {
        path: '/payment/transaction',
        element: <Transaction />
    },
    {
        path: '/payment/ss-transaction',
        element: <SSTransaction />
    },
    {
        path: '/payment/ss-transaction/:id/:txnId',
        element: <SsTransactionDetails />
    },
    {
        path: '/payment/payout',
        element: <Payout />
    },
    {
        path: '/payment/payout-w-client',
        element: <PayoutWClient />
    },
    {
        path: '/payment/bank',
        element: <ManualBank />
    },
    {
        path: '/payment/debit-banks',
        element: <DebitBank />
    },
    {
        path: '/payment/payout-banks',
        element: <PayoutBank />
    },
    {
        path: '/payment/bank-transaction',
        element: <BankTransaction />
    },
    {
        path: '/payment/unclaim-bank-transaction',
        element: <IsGetBankTransaction />
    },
    {
        path: '/payment/bank-transaction/:bankId',
        element: <BankTransactionDetails />
    },
    {
        path: '/payment/bank-login',
        element: <BankLogin />
    },
    {
        path: 'payment/reconciliation',
        element: <Reconcilation />
    },
    {
        path: 'support/settlement',
        element: <Settlement />
    },
    {
        path: 'support/payout-credit',
        element: <PayoutCredit />
    },
    {
        path: 'support/reports',
        element: <Reports />
    },
    {
        path: 'payment/payout-control',
        element: <PayoutControl />
    },
    {
        path: 'support/logs',
        element: <Logs />
    },
    {
        path: 'support/merchant',
        element: <SettlementRequest />
    },
    {
        path: 'support/affiliate',
        element: <AffiliateSettlement />
    },
    {
        path: 'support/payout-request',
        element: <PayoutRequest />
    },
    {
        path: 'support/webhooks',
        element: <Webhooks />
    },
    {
        path: 'support/customer-data',
        element: <CustomerData />
    },
    {
        path: 'developer/pg',
        element: <PG />
    },
    {
        path: 'developer/proxy',
        element: <Proxy />
    },
    {
        path: 'developer/super-admins',
        element: <SuperAdmins />
    },
    {
        path: 'developer/crypto-wallet',
        element: <Wallets />
    },
    {
        path: 'payment/usdt-txn',
        element: <USDTTxn />
    },
    {
        path: 'developer/ip-whitelist',
        element: <IPWhitelist />
    },
    {
        path: 'developer/key-config',
        element: <KeyConfig />
    },
    {
        path: 'developer/payin-client',
        element: <PayinClient />
    },
    {
        path: 'developer/merchant-config',
        element: <MerchantConfig />
    },
    {
        path: 'developer/unauth-locations',
        element: <UnauthLocations />
    },
    {
        path: 'developer/merchant-intent-config',
        element: <MerchantIntentConfig />
    },
    {
        path: 'developer/clickpay-config',
        element: <ClickPayConfig />
    },
    {
        path: 'developer/merchant-meta-config',
        element: <MerchantMeta />
    },
    {
        path: 'developer/merchant-vpn',
        element: <MerchantVPN />
    },
    {
        path: 'developer/secret',
        element: <Secret />
    },
    {
        path: 'developer/loggedIn-merchants',
        element: <LoggedInMerchants />
    },
    {
        path: 'developer/urls',
        element: <URL />
    },
    {
        path: 'developer/upi-apps',
        element: <UPIApps />
    },
    {
        path: 'developer/bulk-webhook',
        element: <BulkWebhook />
    },
    {
        path: 'developer/cb-settlements',
        element: <CBSettlement />
    },
    {
        path: 'developer/bulk-payment',
        element: <BulkPayment />
    },
    {
        path: 'developer/update-password',
        element: <UpdatePassword />
    },
    {
        path: 'tracker/upload-reports',
        element: <UploadReports />
    },
    {
        path: 'tracker/bank-txn-reports',
        element: <BankTxnReport />
    },
    {
        path: 'developer/affiliates',
        element: <Affiliates />
    },
    {
        path: 'developer/bank',
        element: <Bank />
    },
    {
        path: 'developer/bot-creds',
        element: <Bots />
    },
    {
        path: 'developer/payout-txn',
        element: <CreatePayout />
    },
    {
        path: 'invoice/:settlementId/:merchantId',
        element: <Invoice />
    },
    {
        path: 'invoice/affiliate/:settlementId/:affiliateId',
        element: <AffilaiteInvoice />
    },
    {
        path: 'pg/payin/:id',
        element: <PayinDetails />
    },
    {
        path: 'pg/payout/:id',
        element: <PayoutDetails />
    }
];

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: childrenRoutes
};

export default AdminRoutes;
