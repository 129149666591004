import React, { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    CardContent,
    Tooltip,
    TablePagination,
    IconButton,
    Popover,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    InputAdornment,
    Autocomplete,
    MenuItem,
    Select
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';
import SearchIcon from '@mui/icons-material/Search';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { getAllActiveWallets, fetchUSDTTxns, fetchMerchants } from 'utils/api';
import { Delete, Edit, RefreshOutlined, Settings } from '@mui/icons-material';
import Loader from 'ui-component/Loader';
import AddConfig from './AddConfig';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axiosServices from 'utils/axios';
import { ISTDate } from 'constant';

// table header options
const headCells = [
    {
        id: 'merchant',
        numeric: false,
        label: 'ID',
        align: 'center'
    },
    {
        id: 'initializeTimeMinutes',
        numeric: true,
        label: 'Wallet',
        align: 'left'
    },
    {
        id: 'pendingTimeMinutes',
        numeric: true,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'mode',
        numeric: true,
        label: 'Mode',
        align: 'left'
    },
    {
        id: 'hash',
        numeric: true,
        label: 'Hash',
        align: 'left'
    },
    {
        id: 'isGet',
        numeric: true,
        label: 'Is Get',
        align: 'left'
    },
    {
        id: 'remark',
        numeric: true,
        label: 'Agent Remark',
        align: 'left'
    },
    {
        id: 'processingTimeMinutes',
        numeric: true,
        label: 'Txn Date',
        align: 'left'
    },
    {
        id: 'desc',
        numeric: true,
        label: 'Description',
        align: 'left'
    },
    {
        id: 'action',
        numeric: true,
        label: 'Action',
        align: 'center'
    }
];

const currentDate = ISTDate;

const USDTTxn = () => {
    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [allData, setAllData] = React.useState();
    const [value, setValue] = React.useState();
    const [selectedValue, setSelectedValue] = React.useState('exactHash');
    const [get, setGet] = React.useState('');

    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [wallets, setMerchants] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [anchorEl, setAnchorEl] = React.useState();

    const fetcMerchantsFromAPI = async () => {
        try {
            let result = await getAllActiveWallets();
            result = result.map((r) => ({
                id: r.walletId || '',
                label: r.walletName || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetcMerchantsFromAPI();
    }, []);

    const fetchBankTransactions = async (searchObj) => {
        try {
            const type = searchObj.selectedValue;
            const value = searchObj.value;
            // if (bank?.value) {
            //     type = 'transactionId';
            //     value = bank?.value;
            // } else if (searchObj.value) {
            //     type = searchObj.selectedValue;
            // }
            const params = {
                transactionId: type === 'transactionId' ? value : '',
                hash: type === 'hash' ? value : '',
                exactHash: type === 'exactHash' ? value : '',
                amount: type === 'amount' ? value : '',
                isGet: get,
                startDate,
                endDate
            };

            const results = await fetchUSDTTxns(params, page, rowsPerPage);
            if (results) {
                setAllData(results?.data);
                setRows(results?.data?.transactionList || []);
            }
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Something went wrong',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchBankTransactions(page, rowsPerPage, startDate, endDate);
            setLoading(false);
            setData(result);
            setRows(result || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataFromAPI();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI();
    };

    const handleGenerateReport = () => {
        setLoading(true);
        const date1 = new Date(startDate).getTime();
        const date2 = new Date(endDate).getTime();
        if (date1 <= date2) {
            const type = selectedValue;
            const payload = {
                transactionId: type === 'transactionId' ? value : '',
                hash: type === 'hash' ? value : '',
                exactHash: type === 'exactHash' ? value : '',
                amount: type === 'amount' ? value : '',
                isGet: get,
                startDate,
                endDate
            };
            axiosServices
                .post(`newUSDTTxnFilterReport`, payload)
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.data || 'Report Generation In progress',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Something went wrong',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                });
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'End date cannot be less than Start date',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const onSearch = (event) => {
        setValue(event.target.value);
    };

    const handleSearch = () => {
        fetchBankTransactions({ value, selectedValue });
    };

    const clearSearch = () => {
        window.location.reload();
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>USDT Transactions</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Refresh">
                            <RefreshOutlined onClick={() => window.location.reload()} style={{ cursor: 'pointer' }} />
                        </Tooltip>
                        <Button size="small" variant="outlined" sx={{ mr: '1rem', ml: 2 }} onClick={handleGenerateReport}>
                            Generate Report
                        </Button>
                        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
                            Add Transaction
                        </Button>
                    </div>
                </div>
            }
        >
            <AddConfig wallets={wallets} open={open} handleCloseDialog={handleCloseDialog} />
            {loading && <Loader />}
            <CardContent>
                <Grid sx={{ ml: 0 }} container justifyContent="start" alignItems="center" spacing={2}>
                    <Select value={selectedValue} onChange={handleChange} sx={{ width: 220, mr: '1rem', mt: 2 }} size="small">
                        <MenuItem value="exactHash">Exact UTR</MenuItem>
                        <MenuItem value="hash">UTR</MenuItem>
                        <MenuItem value="transactionId">Transaction ID</MenuItem>
                        <MenuItem value="amount">Amount</MenuItem>
                    </Select>
                    <TextField
                        sx={{ width: 220, mr: '1rem', mt: 2 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                        onChange={onSearch}
                        type={selectedValue === 'amount' ? 'number' : 'string'}
                        placeholder="Enter Search Value"
                        value={value}
                        size="small"
                    />

                    <Autocomplete
                        id="isGet"
                        className="isGet-select"
                        name="isGet"
                        options={['YES', 'NO']}
                        onChange={(e, value) => {
                            setGet(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setGet(value);
                                }}
                                size="small"
                                sx={{ width: 220, mr: 2, mt: 2 }}
                                label="IS GET"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item justifyContent="end" alignItems="center" spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid
                                container
                                style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }}
                                xs={12}
                                sm={12}
                                md={12}
                                alignItems="center"
                            >
                                <DatePicker
                                    inputFormat="YYYY/MM/DD"
                                    value={startDate}
                                    onChange={(value) => {
                                        const dt = new Date(value);
                                        const stDate = dayjs(dt).format('YYYY-MM-DD');
                                        setStartDate(stDate);
                                    }}
                                    maxDate={ISTDate}
                                    renderInput={(params) => <TextField sx={{ width: 210, mr: 2, mt: 2 }} size="small" {...params} />}
                                    label="Start Date"
                                />
                                <DatePicker
                                    inputFormat="YYYY/MM/DD"
                                    value={endDate}
                                    minDate={startDate}
                                    maxDate={ISTDate}
                                    onChange={(value) => {
                                        const dt = new Date(value);
                                        const enDate = dayjs(dt).format('YYYY-MM-DD');
                                        setEndDate(enDate);
                                    }}
                                    renderInput={(params) => <TextField sx={{ width: 210, mr: 2, mt: 2 }} size="small" {...params} />}
                                    label="End Date"
                                />
                                <Button size="small" variant="contained" sx={{ mr: '1rem', ml: '1rem', mt: 2 }} onClick={handleSearch}>
                                    Apply
                                </Button>
                                <Button size="small" variant="contained" sx={{ mr: '1rem', mt: 2 }} color="secondary" onClick={clearSearch}>
                                    Clear
                                </Button>
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </CardContent>

            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover>
                                    <TableCell align="center">{row.bankTransactionId?.usdtBankTransactionId}</TableCell>
                                    <TableCell>
                                        <div>
                                            <div>{row.bankTransactionId?.walletId}</div>
                                            <div>{row.walletName}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.mode}</TableCell>
                                    <TableCell>{row.hash}</TableCell>
                                    <TableCell>{row.isGet}</TableCell>
                                    <TableCell>{row.agentRemark}</TableCell>
                                    <TableCell>{row.txnDate}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={allData?.totalSize || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default USDTTxn;
